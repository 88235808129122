import React from 'react';
import { TextField, Button } from '@material-ui/core';
import InstructionsList from './InstructionsList';

interface InputBoxProps {
  text: string;
  handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  availableInstructions: any[];
  instructions: any[];
  handleDeleteInstruction: (index: number) => void;
  handleDragEnd: (result: any) => void;
  handleAddInstruction: (instruction: any) => void;
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isProcessing: boolean;
  screenWidth: number;
}

const InputBox: React.FC<InputBoxProps> = ({
  text,
  handleTextChange,
  instructions,
  availableInstructions,
  handleDeleteInstruction,
  handleDragEnd,
  handleAddInstruction,
  handleSubmit,
  isProcessing,
  screenWidth,
}) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <h2 style={{ textAlign: 'center', color: '#333' }}>How can we help?</h2> */}
        <TextField
          style={{ marginBottom: '16px', width: '100%' }}
          label="Enter your text"
          aria-label='Enter your text'
          value={text}
          onChange={handleTextChange}
          fullWidth
          multiline
          minRows={2}
          maxRows={2}
          variant="outlined"
        />
        <InstructionsList
          instructions={instructions}
          handleDeleteInstruction={handleDeleteInstruction}
          handleDragEnd={handleDragEnd}
          handleAddInstruction={handleAddInstruction}
          availableInstructions={availableInstructions}
          singleInstruction={false}
        />
        <Button
          style={{
            fontFamily: "Roboto Slab, Baskerville, Times New Roman",
            fontWeight: 'bold',
            textTransform: 'none',
            marginTop: '16px',
            backgroundColor: '#A65A38',
            color: '#fff',
          }}
          disabled={isProcessing}
          onClick={(e) => handleSubmit(e)}
          variant="contained"
        >
          {isProcessing ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    );
  };
  
  export default InputBox;
  
