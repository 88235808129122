import React, { useState, useEffect } from "react";
import { BACKEND_URL } from "../../Constants";
import { headers } from "../../shared/misc";
import { useFetchResults } from "../../shared/useFetchResults";
import { RouteParams } from "../../shared/types/RouteParams";
import { useParams } from "react-router-dom";
import "./Wizard.css";
import { Bars } from "react-loader-spinner";

const WizardChapterOutlines: React.FC = (): JSX.Element => {
  const [currentChapter, setCurrentChapter] = useState(1);
  const [chapterOutlines, setChapterOutlines] = useState<string>("");
  const [chapterOutlinesArray, setChapterOutlinesArray] = useState<string[]>(
    []
  );
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setChapterOutlinesArray(chapterOutlines.split("Chapter "));
  }, [chapterOutlines]);

  const handleSubmit = async () => {
    setIsProcessing(true);

    const response = await fetch(`${BACKEND_URL}/process`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        text: chapterOutlines.concat(" "),
        instructions: ["chapter_outline_to_story"],
        model: "gpt-3.5-turbo",
        page: "wizard/story",
      }),
    });

    if (response.ok) {
      const data = await response.json();
      window.location.href = `/wizard/story/${data.guid}`;
    } else {
      alert("An error occurred while processing your request");
    }
    setIsProcessing(false);
  };

  const { guid } = useParams<RouteParams>();
  useFetchResults({
    actualGuid: guid ?? "",
    setResponseText: setChapterOutlines,
    setSnackbarMessage: () => {},
    setSnackbarOpen: () => {},
  });

  return (
    <>
      <div className="container">
        {chapterOutlinesArray ? (
          <div className="inputContainer">
            <label className="label">{`Edit chapter ${
              currentChapter
            } outline:`}</label>
            <textarea
              value={chapterOutlinesArray[currentChapter]}
              onChange={(e) => {
                const newOutlines = [...chapterOutlinesArray];
                newOutlines[currentChapter] = e.target.value;
                setChapterOutlinesArray(newOutlines);
              }}
              className="textarea"
            />
          </div>
        ) : (
          <Bars
            height="80"
            width="80"
            color="#1E1C1B"
            ariaLabel="bars-loading"
          />
        )}
        <button
          className="button"
          disabled={currentChapter <= 1}
          onClick={() => setCurrentChapter(currentChapter - 1)}
        >
          Previous
        </button>
        <button
          className="button"
          disabled={currentChapter >= chapterOutlinesArray.length - 1}
          onClick={() => setCurrentChapter(currentChapter + 1)}
        >
          Next
        </button>
        <button
          className="button"
          onClick={handleSubmit}
          disabled={isProcessing}
        >
          Create Story
        </button>
      </div>
    </>
  );
};

export default WizardChapterOutlines;
