import React, { useState } from "react";
import { BACKEND_URL } from "../../Constants";
import { headers } from "../../shared/misc";
import { useFetchResults } from "../../shared/useFetchResults";
import { useParams } from "react-router-dom";
import { RouteParams } from "../../shared/types/RouteParams";
import "./Wizard.css";
import { Bars } from "react-loader-spinner";

const WizardIdeas: React.FC = (): JSX.Element => {
  const [selectedIdea, setSelectedIdea] = useState<string | null>(null);
  const [numChapters, setNumChapters] = useState(5);
  const [isProcessing, setIsProcessing] = useState(false);
  const [ideas, setIdeas] = useState<string>();

  const handleSubmit = async () => {
    setIsProcessing(true);

    const response = await fetch(`${BACKEND_URL}/process`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        text: selectedIdea + " It should be exactly " + numChapters + " chapters long.",
        instructions: ["idea_to_detailed_outline"],
        model: "gpt-3.5-turbo",
        page: "wizard/outline",
      }),
    });

    if (response.ok) {
      const data = await response.json();
      window.location.href = `/wizard/outline/${data.guid}`;
    } else {
      alert("An error occurred while processing your request");
    }
    setIsProcessing(false);
  };
  
  const { guid } = useParams<RouteParams>();
  useFetchResults({
    actualGuid: guid ?? "",
    setResponseText: setIdeas,
    setSnackbarMessage: () => {},
    setSnackbarOpen: () => {},
  });

  return (
    <div className="container">
      {ideas?.split("Idea ").map((idea, index) => (
        <div 
          key={index}
          onClick={() => setSelectedIdea(idea)}
          className={`ideaContainer ${selectedIdea === idea ? "selectedIdea" : ""}`}
        >
          {idea}
        </div>
      ))}
      {!ideas && (
        <Bars height="80" width="80" color="#1E1C1B" ariaLabel="bars-loading" />
      )}
      <div className="inputContainer">
        <label className="label">Number of chapters (5-30):</label>
        <input
          type="number"
          value={numChapters}
          onChange={(e) =>
            setNumChapters(Math.min(Math.max(parseInt(e.target.value), 5), 30))
          }
          min={5}
          max={30}
          className="input"
        />
      </div>
      <button className="button" onClick={handleSubmit}  disabled={isProcessing}>
        Create Initial Outline
      </button>
    </div>
  );
};

export default WizardIdeas;
