import React, { useState } from "react";
import { BACKEND_URL } from "../../Constants";
import { headers } from "../../shared/misc";
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import './Wizard.css';
import { ActionMeta, MultiValue } from "react-select";

interface OptionType {
  label: string;
  value: string;
}

const genresList: OptionType[] = [
  "Action",
  "Adventure",
  "Comedy",
  "Crime",
  "Drama",
  "Fantasy",
  "Historical",
  "Horror",
  "Mystery",
  "Romance",
  "Sci-Fi",
  "Thriller",
].map((genre) => ({ label: genre, value: genre }));

const WizardIdea: React.FC = (): JSX.Element => {
  const [prompt, setPrompt] = useState<string>("");
  const [genres, setGenres] = useState<MultiValue<any> | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const animatedComponents = makeAnimated();

  const handleSubmit = async () => {
    setIsProcessing(true);
    const genreString = genres ? `\n\nGenres: ${genres?.map((genre) => genre.value).join(", ")}`: "";
    const response = await fetch(`${BACKEND_URL}/process`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        text: `${prompt}${genreString}`,
        instructions: ['idea_to_ideas'],
        model: 'gpt-3.5-turbo',
        page: 'wizard/ideas',
      }),
    });

    if (response.ok) {
      const data = await response.json();
      window.location.href = `/wizard/ideas/${data.guid}`;
    } else {
      alert("An error occurred while processing your request");
    }

    setIsProcessing(false);
  };

  const handleChange = (newValue: MultiValue<unknown>, actionMeta: ActionMeta<unknown>) => {
    if (newValue) {
      setGenres(newValue);
    }
  };

  return (
    <>
      <div className="container">
        <div className="inputContainer">
          <label className="label">What's your idea? (this can be a short description or even just a couple of nouns)</label>
          <textarea
            wrap="soft"
            className="textarea"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <div className="inputContainer">
          <label className="label">Select up to 5 genres:</label>
          <CreatableSelect
            isMulti
            components={animatedComponents}
            options={genresList}
            onChange={handleChange}
            className="input"
            isSearchable
            isClearable
            closeMenuOnSelect={false}
          />
        </div>
        <button className="button" onClick={handleSubmit} disabled={isProcessing}>
          Get Ideas
        </button>
      </div>
    </>
  );
};

export default WizardIdea;
