import "./App.css";
import TextProcessor from "./TextProcessor";
import Callback from "./Callback";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SubscriptionManagement from "./subscriptions/SubscriptionManagement";
import WizardIdea from "./tools/Wizard/WizardIdea";
import WizardIdeas from "./tools/Wizard/WizardIdeas";
import WizardOutline from "./tools/Wizard/WizardOutline";
import WizardChapterOutlines from "./tools/Wizard/WizardChapterOutlines";
import WizardStory from "./tools/Wizard/WizardStory";
import LandingPage from "./LandingPage";
import SAAppBar from "./SAAppBar";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SAAppBar />
      </ThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/plot" element={<TextProcessor tab={0} />} />
          <Route path="/plot/:guid" element={<TextProcessor tab={0} />} />
          <Route path="/history" element={<TextProcessor tab={3} />} />
          <Route path="/character/:guid" element={<TextProcessor tab={1} />} />
          <Route path="/character" element={<TextProcessor tab={1} />} />
          <Route path="/editing/:guid" element={<TextProcessor tab={2} />} />
          <Route path="/editing" element={<TextProcessor tab={2} />} />
          <Route path="/signincallback" element={<Callback />} />
          <Route path="/subscriptions" element={<SubscriptionManagement />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/wizard/idea" element={<WizardIdea />} />
          <Route path="/wizard/ideas" element={<WizardIdeas />} />
          <Route path="/wizard/ideas/:guid" element={<WizardIdeas />} />
          <Route path="/wizard/outline" element={<WizardOutline />} />
          <Route path="/wizard/outline/:guid" element={<WizardOutline />} />
          <Route path="/wizard/chapteroutlines" element={<WizardChapterOutlines />} />
          <Route path="/wizard/chapteroutlines/:guid" element={<WizardChapterOutlines />} />
          <Route path="/wizard/story" element={<WizardStory />} />
          <Route path="/wizard/story/:guid" element={<WizardStory />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
