export type FormDataCategory =
  | "basicInformation"
  | "physicalAppearance"
  | "personality"
  | "background"
  | "relationships"
  | "skillsAndAbilities";

interface Field {
  label: string;
  inputType: string;
  inputStyle: string;
  name: string;
  category: FormDataCategory;
}

const fieldsData: Field[] = [
  // Basic Information
  {
    label: "Character Name:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "characterName",
    category: "basicInformation",
  },
  {
    label: "Age:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "age",
    category: "basicInformation",
  },
  {
    label: "Gender:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "gender",
    category: "basicInformation",
  },
  {
    label: "Species:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "species",
    category: "basicInformation",
  },
  {
    label: "Occupation:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "occupation",
    category: "basicInformation",
  },

  // Physical Appearance
  {
    label: "Height:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "height",
    category: "physicalAppearance",
  },
  {
    label: "Weight:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "weight",
    category: "physicalAppearance",
  },
  {
    label: "Hair Color:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "hairColor",
    category: "physicalAppearance",
  },
  {
    label: "Eye Color:",
    inputType: "text",
    inputStyle: "character-creation-form-input-text-style",
    name: "eyeColor",
    category: "physicalAppearance",
  },
  {
    label: "Distinguishing Features:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "distinguishingFeatures",
    category: "physicalAppearance",
  },
  {
    label: "Clothing Style:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "clothingStyle",
    category: "physicalAppearance",
  },

  // Personality
  {
    label: "Personality Traits:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "personalityTraits",
    category: "personality",
  },
  {
    label: "Character Flaws:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "characterFlaws",
    category: "personality",
  },
  {
    label: "Quirks or Habits:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "quirksOrHabits",
    category: "personality",
  },
  {
    label: "Core Beliefs:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "coreBeliefs",
    category: "personality",
  },
  {
    label: "Fears:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "fears",
    category: "personality",
  },

  // Background
  {
    label: "Family Background:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "familyBackground",
    category: "background",
  },
  {
    label: "Birthplace:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "birthplace",
    category: "background",
  },
  {
    label: "Socioeconomic Status:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "socioeconomicStatus",
    category: "background",
  },
  {
    label: "Education:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "education",
    category: "background",
  },
  {
    label: "Important Life Events:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "importantLifeEvents",
    category: "background",
  },

  // Relationships
  {
    label: "Family:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "family",
    category: "relationships",
  },
  {
    label: "Friends:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "friends",
    category: "relationships",
  },
  {
    label: "Enemies:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "enemies",
    category: "relationships",
  },
  {
    label: "Romantic Relationships:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "romanticRelationships",
    category: "relationships",
  },
  {
    label: "Professional Relationships:",
    inputType: "textarea",
    inputStyle: "character-creation-form-textarea",
    name: "professionalRelationships",
    category: "relationships",
  },
];

export default fieldsData;
