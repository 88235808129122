import React, { useState, FormEvent } from 'react';
import { loadStripe, Stripe as StripeClient, StripeCardElement } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('your_stripe_public_key');

interface SubscriptionData {
  elements: ReturnType<typeof useElements>;
  stripe: ReturnType<typeof useStripe>;
  email: string;
  isTrial: boolean;
}

async function handleSubscription({ elements, stripe, email, isTrial }: SubscriptionData) {
  if (!stripe || !elements) {
    return;
  }

  const card = elements.getElement(CardElement);
  const result = await stripe.createToken(card as StripeCardElement);

  if (result.error) {
    console.error(result.error);
  } else {
    const response = await fetch('/api/create-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        isTrial,
        token: result.token.id,
      }),
    });

    const data = await response.json();

    if (data.error) {
      console.error(data.error);
    } else {
      console.log('Subscription successful');
    }
  }
}

interface SubscriptionFormProps {
  isTrial: boolean;
}

const SubscriptionForm: React.FC<SubscriptionFormProps> = ({ isTrial }) => {
  const [email, setEmail] = useState('');
  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSubscription({ elements, stripe, email, isTrial });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <CardElement />
      <button type="submit" disabled={!stripe}>
        {isTrial ? 'Start Trial' : 'Subscribe'}
      </button>
    </form>
  );
};

const SubscriptionManagement: React.FC = () => {
  const [isTrial, setIsTrial] = useState(false);

  return (
    <Elements stripe={stripePromise}>
      <h1>Subscription Management</h1>
      <label>
        <input
          type="checkbox"
          checked={isTrial}
          onChange={() => setIsTrial(!isTrial)}
        />
        Try for free
      </label>
      <SubscriptionForm isTrial={isTrial} />
    </Elements>
  );
};

export default SubscriptionManagement;
