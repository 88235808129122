import React from 'react';
import './LandingPage.css'; // You'll need to create this file and define the styles

const LandingPage = () => {
    return (
        <div className="landingPage" style={{backgroundColor: 'rgb(249, 245, 242)', fontFamily: 'Roboto Slab'}}>
            <main>
                <section className="welcomeSection">
                    <h2>Transform Your Writing Journey Today!</h2>
                    <p>Story Assist supercharges your writing process, offering a unique suite of tools designed to streamline the creation of fictional stories. From innovative story creation wizards to advanced rewriting tools, we’ve got you covered.</p>
                    <button style={{backgroundColor: 'rgb(166, 90, 56)', color: 'white'}} onClick={() => window.location.href='/wizard/idea'}>Embark on Your Writing Adventure</button>
                </section>
                <section className="aboutSection">
                    <h2>Why Choose Story Assist?</h2>
                    <p>Story Assist is the ultimate ally for writers at every level. Our platform's advanced features - including a dynamic character generator and AI-powered feedback - are engineered to elevate your storytelling to new heights.</p>
                    <img style={{width:"50px"}} src="https://story-assist-ui.s3.amazonaws.com/quill.svg" alt="About Story Assist"/>
                </section>
                <section className="gifSection">
                    <h2>Experience the Power of Story Assist</h2>
                    <p>Get a sneak peek of our intuitive platform in action, and discover how Story Assist can revolutionize your writing process.</p>
                    <img src="https://story-assist-ui.s3.amazonaws.com/gif.gif" alt="Story Assist Demo"/>
                </section>
            </main>
            <footer style={{backgroundColor: '#1E1C1B', color: 'white'}}>
                <p>&copy; 2023 Story Assist. All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default LandingPage;
