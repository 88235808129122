import React from "react";
import {
  AppBar,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import UserGreeting from "./UserGreeting";
import { BACKEND_URL } from "./Constants";
import { headers } from "./shared/misc";

const useStyles = makeStyles((theme) => ({
  appBarStyle: {
    backgroundColor: "#1E1C1B",
    display: "flex",
    justifyContent: "space-between", // Changed from 'center' to 'space-between'
  },
  buttonStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    padding: "12px",
    marginLeft: "10px",
    textTransform: "capitalize",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
    color: "#FFF",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  mobileAppBarStyle: {
    flexDirection: "column",
    backgroundColor: "#1E1C1B",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const headlines: string[] = [
  "Unleash Your Creativity with Story Assist",
  "Empower Your Writing Journey with Story Assist",
  "Master the Art of Storytelling with Story Assist",
  "Story Assist: Your Partner in Crafting Compelling Narratives",
  "Transform Your Writing Process with Story Assist",
  "Story Assist: Nurturing Your Narrative Potential",
  "Elevate Your Prose with the Power of Story Assist",
  "Harness the Power of Your Imagination with Story Assist",
  "Bring Your Stories to Life with Story Assist",
  "Discover Your Writing Superpowers with Story Assist",
  "Unfold Your Narrative Masterpiece with Story Assist",
];

const randomHeadline = headlines[Math.floor(Math.random() * headlines.length)];

const SAAppBar: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (location: string) => {
    window.location.href = location;
  };

  function useBalance(): { spend: number; max_spend: number } {
    const [balance, setBalance] = React.useState({ spend: 0, max_spend: 0 });

    React.useEffect(() => {
      async function getBalance() {
        const response = await fetch(`${BACKEND_URL}/spend`, {
          headers,
        });
        const data = await response.json();
        setBalance(data);
      }
      getBalance();
    }, []);

    return balance;
  }

  return (
    <AppBar position="static" className={isMobile ? classes.mobileAppBarStyle : classes.appBarStyle}>
      <h1 className={isMobile ? '' : ''} onClick={() => (window.location.href = "/")}>{randomHeadline}</h1>
      <div style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
        <div style={{ float: "left" }}>
          <Button
            className={isMobile ? classes.buttonStyle : classes.buttonStyle}
            onClick={() => handleClick("/wizard/idea")}
          >
            Story Wizard
          </Button>
          <Button
            className={isMobile ? classes.buttonStyle : classes.buttonStyle}
            onClick={() => handleClick("/character")}
          >
            Character Tools
          </Button>
          <Button
            className={isMobile ? classes.buttonStyle : classes.buttonStyle}
            onClick={() => handleClick("/editing")}
          >
            Story Tools
          </Button>
          <Button
            className={isMobile ? classes.buttonStyle : classes.buttonStyle}
            onClick={() => handleClick("/history")}
          >
            History
          </Button>
        </div>
        <div style={{ float: "right" }}>
        <UserGreeting user="User" balance={useBalance()} />
      </div>
    </div>
  </AppBar>
);
};

export default SAAppBar;
