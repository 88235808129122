export default interface FormData {
  basicInformation: {
    characterName: string;
    age: string;
    gender: string;
    species: string;
    occupation: string;
  };
  physicalAppearance: {
    height: string;
    weight: string;
    hairColor: string;
    eyeColor: string;
    distinguishingFeatures: string;
    clothingStyle: string;
  };
  personality: {
    personalityTraits: string;
    characterFlaws: string;
    quirksOrHabits: string;
    coreBeliefs: string;
    fears: string;
  };
  background: {
    familyBackground: string;
    birthplace: string;
    socioeconomicStatus: string;
    education: string;
    significantLifeEvents: string;
  };
  relationships: {
    significantOther: string;
    friends: string;
    rivals: string;
    roleModel: string;
    mentor: string;
  };
  skillsAndAbilities: {
    topSkills: string;
    hobbies: string;
    uniqueAbilities: string;
  };
  characterArc: {
    characterGoal: string;
    internalConflict: string;
    externalConflict: string;
    turningPoints: string;
    resolution: string;
  };
}

export const InitialFormData: FormData = {
  basicInformation: {
    characterName: "",
    age: "",
    gender: "",
    species: "",
    occupation: "",
  },
  physicalAppearance: {
    height: "",
    weight: "",
    hairColor: "",
    eyeColor: "",
    distinguishingFeatures: "",
    clothingStyle: "",
  },
  personality: {
    personalityTraits: "",
    characterFlaws: "",
    quirksOrHabits: "",
    coreBeliefs: "",
    fears: "",
  },
  background: {
    familyBackground: "",
    birthplace: "",
    socioeconomicStatus: "",
    education: "",
    significantLifeEvents: "",
  },
  relationships: {
    significantOther: "",
    friends: "",
    rivals: "",
    roleModel: "",
    mentor: "",
  },
  skillsAndAbilities: {
    topSkills: "",
    hobbies: "",
    uniqueAbilities: "",
  },
  characterArc: {
    characterGoal: "",
    internalConflict: "",
    externalConflict: "",
    turningPoints: "",
    resolution: "",
  },
};
