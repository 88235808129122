import React, { useState, useEffect } from "react";
import SASnackbar from "./SASnackbar";
import { Box } from "@material-ui/core";
import { BACKEND_URL } from "./Constants";
import EdittingTools from "./tools/editting-tools";
import CharacterCreationForm from "./tools/CharacterCreation/CharacterCreationPage";
import { headers } from "./shared/misc";
import PlotTools from "./tools/PlotTools";
import { downloadResults } from "./shared/backend-client";
import History from "./History";

interface TextProcessorProps {
  tab?: number;
}

const TextProcessor: React.FC<TextProcessorProps> = ({ tab = 0 }) => {
  const [resultsGuid, setResultsGuid] = useState<string>(" ");
  const [resultsResponseText, setResultsResponseText] = useState<string>(" ");
  const [currentTab, setCurrentTab] = useState<number>(tab);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const handleTabChange = (newValue: number) => {
    console.log(newValue);
    setCurrentTab(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleButtonClick = async () => {
    if (!resultsGuid) return;

    const response = await fetch(
      `${BACKEND_URL}/result_url/${resultsGuid.trim()}`,
      { headers }
    );
    if (response.ok) {
      const data = await response.json();
      const result = await downloadResults(data.download_url);
      setResultsResponseText(result);
    } else {
      alert("An error occurred while fetching the results");
    }
  };

  // Add this state variable to hold screen width
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  // Add this useEffect to update screenWidth when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        style={{
          maxWidth: "800px",
          margin: "10px auto",
          backgroundColor: "#F9F5F2",
          borderRadius: "10px",
          padding: "10px 30px 30px 30px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Roboto Slab, Baskerville, Times New Roman",
        }}
      >
        <Box hidden={currentTab !== 0}>
          {/* <PlotTools
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            screenWidth={screenWidth}
          /> */}
        </Box>
        <Box hidden={currentTab !== 1}>
          <CharacterCreationForm
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            screenWidth={screenWidth}
          />
        </Box>
        <Box hidden={currentTab !== 2}>
          <EdittingTools
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            screenWidth={screenWidth}
          />
        </Box>
        <Box hidden={currentTab !== 3}>
          <History />
        </Box>
        <Box hidden={currentTab !== 4}>How-To</Box>
      </div>
      <SASnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </>
  );
};

export default TextProcessor;
