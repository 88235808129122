import React, { useState, useEffect } from "react";
import "./History.css";
import { Result, Manifest } from "./shared/types/History";
import { BACKEND_URL } from "./Constants";
import { headers } from "./shared/misc";
import { Button } from "@material-ui/core";

const History: React.FC = () => {
  const [results, setResults] = useState<Result[]>([]);
  const [showFlyout, setShowFlyout] = useState(false);
  const [manifest, setManifest] = useState<Manifest | null>(null);

  useEffect(() => {
    fetch(`${BACKEND_URL}/results`, { headers: headers })
      .then((res) => res.json())
      .then((data: Result[]) => {
        if (data && data.length > 0) {
          setResults(data.sort((a, b) => new Date(b.date).getUTCDate() - new Date(a.date).getUTCDate()));
        }
      });
  }, []);

  const handleButtonClick = async (id: string) => {
    try {
      const res = await fetch(`${BACKEND_URL}/manifest_url/${id}`, { headers: headers });
      const data: Manifest = await res.json();
      console.log(data);
  
      const downloadRes = await fetch(data.download_url);
      const downloadData: Manifest = await downloadRes.json();
  
      setManifest(downloadData);
      setShowFlyout(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  const closeFlyout = () => {
    setShowFlyout(false);
  };

  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>{renderValue(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <ul>
          {Object.entries(value).map(([key, val], index) => (
            <li key={index}>
              {key}: {renderValue(val)}
            </li>
          ))}
        </ul>
      );
    } else {
      return <span>{value}</span>;
    }
  };
  

  return (
    <div className="results-container">
      <ul className="results-list">
        {results.map((result) => (
          <li key={result.id}>
            <button
              className="results-button"
              onClick={() => handleButtonClick(result.id)}
            >
              {new Date(result.date).toLocaleString()}
            </button>
          </li>
        ))}
      </ul>
      {showFlyout && (
        <div className="flyout-container">
          <button className="close-flyout" onClick={closeFlyout}>
            &times;
          </button>
          <div className="manifest-data">
            {manifest && (
              <>
      {/* <p>GUID: {renderValue(manifest.guid)}</p> */}
      <p>Page URL: <Button onClick={() => {window.location.href=manifest.page_url}}>View Result</Button></p>
      <p>Timestamp: {renderValue(manifest.timestamp)}</p>
      <p>Cost: {renderValue(manifest.cost)}</p>
      <p>Instructions: {renderValue(manifest.instructions)}</p>
      <p>Text: {renderValue(manifest.text)}</p>
      {/* <p>Model: {renderValue(manifest.model)}</p> */}
      {/* <p>Username: {renderValue(manifest.username)}</p> */}
      {/* <p>Page: {renderValue(manifest.page)}</p> */}
      {/* <p>Download URL: {renderValue(manifest.download_url)}</p> */}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default History;
