import React, { useState, ChangeEvent } from "react";
import FormData, { InitialFormData } from "../../shared/types/FormData";
import CharacterInput from "./CharacterInput";
import "./CharacterCreationForm.css";
import fieldsData, { FormDataCategory } from "./fieldData";

interface CharacterCreationFormProps {
  initData: FormData | null | undefined;
  onChange: (formData: FormData) => void;
}

const CharacterCreationForm: React.FC<CharacterCreationFormProps> = ({
  initData,
  onChange,
}) => {
  const [formData, setFormData] = useState<FormData>(
    initData || InitialFormData
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    category: keyof FormData
  ) => {
    setFormData({
      ...formData,
      [category]: {
        ...formData[category],
        [event.target.name]: event.target.value,
      },
    });
    if (onChange) {
      onChange(formData);
    }
  };

  function hasKey<O>(obj: object, key: PropertyKey): key is keyof O {
    return key in obj;
  }

  function getFormValue(category: FormDataCategory, name: string): string {
    const categoryData = formData[category];
    if (categoryData && hasKey(categoryData, name)) {
      const value = categoryData[name];
      if (value) {
        return value;
      }
    }
    return "";
  }

  return (
    <div>
      {fieldsData.map((field) => (
        <CharacterInput
          key={field.name}
          label={field.label}
          inputType={field.inputType}
          inputStyle={field.inputStyle}
          name={field.name}
          value={getFormValue(field.category, field.name)}
          category={field.category}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

export default CharacterCreationForm;
