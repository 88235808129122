import { BACKEND_URL } from "../Constants";
import { headers } from "./misc";

export function get(path: string, successFunction: (data: string) => void, errorFunction: (error: string) => void) {
  fetch(BACKEND_URL + path, {
    method: "GET",
    headers: headers,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Failed to get");
      }
    })
    .then((data) => {
      successFunction(data);
    })
    .catch((error) => {
      errorFunction(error);
    });
}
 export function post(path: string, formData: any, errorFunction: (err: any) => void, successFunction: (data: any) => void) {
  fetch(BACKEND_URL + path, {
    method: "POST",
    body: formData,
    headers: headers,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Failed to post");
      }
    })
    .then((data) => {
      successFunction(data);
    })
    .catch((error) => {
      errorFunction(error);
    });
}

export const downloadResults = async (download_url: string) => {
  const response = await fetch(download_url);
  if (response.ok) {
    const text = await response.json();
    return text;
  } else {
    throw new Error("An error occurred while downloading the results");
  }
};

// if (response.ok) {
//   const data = await response.json();
//   setGuid(data.guid);
//   setProcessingTime(new Date().getTime() - startTime);
//   setIsProcessing(false);
// } else {
//   alert("An error occurred while processing your request");
// }