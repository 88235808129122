import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { post } from "../../shared/backend-client";
import CharacterCreationForm from "./CharacterCreationForm";
import "./CharacterCreationForm.css";
import FormData from "../../shared/types/FormData";
import { RouteParams } from "../../shared/types/RouteParams";
import { Bars } from "react-loader-spinner";
import ToolsProps from "../ToolsProps";
import { useFetchResults } from "../../shared/useFetchResults";

const CharacterCreationPage: React.FC<ToolsProps> = ({
  setSnackbarMessage,
  setSnackbarOpen,
  screenWidth,
}) => {
  const [responseText, setResponseText] = useState<string>("");
  const [formData, setFormData] = useState<FormData | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { guid } = useParams<RouteParams>();
  let actualGuid: string = guid || "";
  const location = useLocation();
  if (!location.pathname.startsWith("/character") || !guid) {
    actualGuid = "";
  }
  const handleFormDataChange = (data: FormData) => {
    setFormData(data);
  };
  const handleSubmit = () => {
    setIsProcessing(true);
    post(
      "/process",
      JSON.stringify({
        instructions: ["character_creation"],
        text: formData,
        model: "gpt-3.5-turbo",
        page: "character",
      }),
      () => {},
      (data) => {
        window.location.href = `/character/${data.guid}`;
        setIsProcessing(false);
      }
    );
  };

  useFetchResults({
    actualGuid,
    setResponseText,
    setSnackbarOpen,
    setSnackbarMessage,
  });

  return (
    <div>
      <h2>Character Creator</h2>
      {guid && !responseText ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#1E1C1B"
            ariaLabel="bars-loading"
          />
        </div>
      ) : (
        <div>
          <CharacterCreationForm
            onChange={handleFormDataChange}
            initData={responseText ? JSON.parse(responseText) : null}
          />
          <Button
            className="process-button"
            disabled={isProcessing}
            onClick={handleSubmit}
            variant="contained"
          >
            {isProcessing ? "Submitting..." : "Submit"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CharacterCreationPage;
