import { CognitoIdentityProviderClient, InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
import { AWS_REGION, COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from "../Constants";

 // Retrieve the refresh token securely, e.g., from local storage

const cognitoIdentityProvider = new CognitoIdentityProviderClient({ region: AWS_REGION });

export async function cognitoRefreshToken(refresh_token: string): Promise<string | undefined> {
  const authParams = {
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_CLIENT_ID,
    AuthFlow: "REFRESH_TOKEN",
    AuthParameters: {
      REFRESH_TOKEN: refresh_token,
    },
  };

  try {
    const authResponse = await cognitoIdentityProvider.send(new InitiateAuthCommand(authParams));
    if (authResponse.AuthenticationResult) {
      return authResponse.AuthenticationResult.AccessToken;
    } else {
      throw new Error("No authentication result found");
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
}
