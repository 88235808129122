import { useEffect } from "react";
import { downloadResults } from "./backend-client";
import { BACKEND_URL } from "../Constants";
import { headers } from "./misc";

interface FetchResultsOptions {
  actualGuid: string | null;
  setResponseText: (text: string) => void;
  setSnackbarOpen: (open: boolean) => void;
  setSnackbarMessage: (message: string) => void;
}

export const useFetchResults = ({
  actualGuid,
  setResponseText,
  setSnackbarOpen,
  setSnackbarMessage,
}: FetchResultsOptions) => {
  useEffect(() => {
    let intervalId: number;

    const fetchResults = async () => {
      if (!actualGuid || actualGuid.length < 6) return;

      const response = await fetch(
        `${BACKEND_URL}/result_url/${actualGuid.trim()}`,
        {
          headers,
        }
      );
      if (response.ok) {
        const data = await response.json();
        const text = await downloadResults(data.download_url);
        clearInterval(intervalId);
        setResponseText(text);
      } else if (response.status === 403) {
        window.location.href =             `https://login.story-assist.com/login?client_id=3gp2pvnnnkqpm8i0sg02emg84k&response_type=token&scope=email+openid+phone&redirect_uri=${window.location.origin}/signincallback`;
        clearInterval(intervalId);
      } else if (response.status === 404) {
      } else {
        setResponseText("Error fetching results");
        clearInterval(intervalId);
      }
    };

    if (actualGuid) {
      intervalId = window.setInterval(fetchResults, 10000);
    }
  }, [actualGuid, setResponseText, setSnackbarOpen, setSnackbarMessage]);
};
