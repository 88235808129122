import { ChangeEvent, useState } from "react";
import InputBox from "../InputBox";
import ResultsBox from "../ResultsBox";
import Instruction from "../types/Instructions";
import { BACKEND_URL } from "../Constants";
import { headers } from "../shared/misc";
import ToolsProps from "./ToolsProps";
import { Commands } from "../EditingCommands";
import { useLocation, useParams } from "react-router-dom";
import { RouteParams } from "../shared/types/RouteParams";
import { useFetchResults } from "../shared/useFetchResults";

const EdittingTools: React.FC<ToolsProps> = ({
  screenWidth,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {
  const [text, setText] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { guid } = useParams<RouteParams>();
  let actualGuid: string = guid || "";
  const location = useLocation();
  if(!location.pathname.startsWith('/editing') || !guid) {
    actualGuid = "";
  }
  const [responseText, setResponseText] = useState<string>(actualGuid ? "" : " ");
  const [instructions, setInstructions] = useState<Instruction[]>([]);

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleAddInstruction = (instruction: Instruction) => {
    setInstructions([...instructions, instruction]);
  };

  const handleDeleteInstruction = (index: number) => {
    const updatedInstructions = instructions.filter((_, i) => i !== index);
    setInstructions(updatedInstructions);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedInstructions = Array.from(instructions);
    const [movedItem] = reorderedInstructions.splice(result.source.index, 1);
    reorderedInstructions.splice(result.destination.index, 0, movedItem);
    setInstructions(reorderedInstructions);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    const response = await fetch(`${BACKEND_URL}/process`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        text,
        instructions: instructions.map((i) => i.id),
        model: 'gpt-3.5-turbo',
        page: 'editing',
      }),
    });

    if (response.ok) {
      const data = await response.json();
      window.location.href = `/editing/${data.guid}`;
    } else {
      alert("An error occurred while processing your request");
    }
    setIsProcessing(false);
  };

useFetchResults({actualGuid, setResponseText, setSnackbarOpen, setSnackbarMessage});

  return (
    <>
      <h2>Story Editting Tools</h2>
      <div
        style={{
          display: "flex",
          flexDirection: screenWidth >= 768 ? "row" : "column",
          justifyContent: "space-between",
          marginBottom: "20px",
          paddingTop: "5px",
          height: "100vh",
        }}
      >
        <InputBox
          text={text}
          handleTextChange={handleTextChange}
          instructions={instructions}
          availableInstructions={Commands}
          handleDeleteInstruction={handleDeleteInstruction}
          handleDragEnd={handleDragEnd}
          handleAddInstruction={handleAddInstruction}
          handleSubmit={handleSubmit}
          isProcessing={isProcessing}
          screenWidth={screenWidth}
        />
        <ResultsBox
          responseText={responseText}
          screenWidth={screenWidth}
        />
      </div>
    </>
  );
};

export default EdittingTools;
