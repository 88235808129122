import React, { ChangeEvent, useState } from "react";
import { FormDataCategory } from "./fieldData";

interface CharacterInputProps {
  label: string;
  inputType: string;
  inputStyle: any;
  name: string;
  value: string;
  category: FormDataCategory;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    category: FormDataCategory
  ) => void;
}

const CharacterInput: React.FC<CharacterInputProps> = ({
  label,
  inputType,
  inputStyle,
  name,
  value,
  category,
  onChange,
}) => {
  const [checked, setChecked] = useState(false);
  const [previousValue, setPreviousValue] = useState(value);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    if (event.target.checked) {
      onChange({ target: { name, value: previousValue } } as any, category);
    } else {
      setPreviousValue(value);
      onChange({ target: { name, value: null } } as any, category);
    }
  };

  return (
    <>
      <label className="character-creation-form-label">
        <div style={{ width: "100%" }}>
          <input
            type="checkbox"
            checked={checked}
            onChange={handleCheckboxChange}
          ></input>
          {label}
        </div>
        {inputType === "textarea" ? (
          <textarea
            wrap="soft"
            className={inputStyle}
            name={name}
            value={value}
            onChange={(e) => onChange(e, category)}
            onClick={(e) => setChecked(true)}
            disabled={!checked}
          />
        ) : (
          <input
            type={inputType}
            className={inputStyle}
            name={name}
            value={value}
            onChange={(e) => onChange(e, category)}
            disabled={!checked}
          />
        )}
      </label>
    </>
  );
};

export default CharacterInput;
