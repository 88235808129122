import jwt from "jwt-decode";
import { cognitoRefreshToken } from "./cognito";

// Function to save access token to local storage
export function saveAccessToken(token: string): void {
  const decodedToken: any = jwt(token);
  const expiration = decodedToken.exp * 1000;
  localStorage.setItem("access_token", token);
  localStorage.setItem("token_expiration", expiration.toString());
}

// Function to save token expiration time to local storage
export function saveTokenExpiration(expiration: string): void {
  localStorage.setItem("token_expiration", expiration);
}

// Function to retrieve token expiration time from local storage
export function getTokenExpiration(): string | null {
  return localStorage.getItem("token_expiration");
}

// Function to check if the access token is expired
export function isTokenExpired(): boolean {
  const expiration = getTokenExpiration();
  if (expiration) {
    return new Date().getTime() >= parseInt(expiration, 10);
  }
  return false;
}

// Function to refresh the access token using Cognito
export async function refreshToken(): Promise<void> {
   const access_token = await cognitoRefreshToken(localStorage.getItem("refresh_token")!);
   if(access_token)
    saveAccessToken(access_token);
}

// Function to retrieve access token from local storage and refresh if expired
export function getAccessToken(): string | null {
  return localStorage.getItem("access_token");
}

interface ParsedHashFragment {
  [key: string]: string;
}

export function parseHashFragment(hash: string): ParsedHashFragment {
  const hashFragment = hash.substring(1);
  const keyValuePairs = hashFragment.split("&");

  return keyValuePairs.reduce((params, keyValue) => {
    const [key, value] = keyValue.split("=");
    return { ...params, [key]: decodeURIComponent(value) };
  }, {});
}

export const headers: HeadersInit = {
  Authorization: `Bearer ${getAccessToken()}`,
  "Content-Type": "application/json",
};

function parseUrlFragments() {
  const urlHash = window.location.hash.substring(1);
  const urlFragments = urlHash.split("&");
  const tokens : any = {};

  urlFragments.forEach((fragment) => {
    const [key, value] = fragment.split("=");
    tokens[key] = decodeURIComponent(value);
  });

  return tokens;
}

function saveTokens(tokens: { access_token: string; refresh_token: string; id_token: string; }) {
  if (tokens.access_token) {
    saveAccessToken(tokens.access_token);
  }

  if (tokens.refresh_token) {
    localStorage.setItem("refresh_token", tokens.refresh_token);
  }

  if (tokens.id_token) {
    localStorage.setItem("id_token", tokens.id_token);
  }
}

// Call this function when your page loads
export function handleAuthentication() {
  const tokens = parseUrlFragments();
  saveTokens(tokens);
}

export function logout() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("token_expiration");
}

export function isLoggedIn(): boolean {
  return !!localStorage.getItem("access_token");
}
