import React from "react";
import { Snackbar } from "@material-ui/core";

interface SASnackbarProps {
  snackbarOpen: boolean;
  handleCloseSnackbar: () => void;
  snackbarMessage: string;
}

const SASnackbar: React.FC<SASnackbarProps> = ({
  snackbarOpen,
  handleCloseSnackbar,
  snackbarMessage,
}) => {
  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={7000}
      onClose={handleCloseSnackbar}
      message={snackbarMessage}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      ContentProps={{
        style: {
          backgroundColor: "#A65A38",
          fontFamily: "Roboto Slab, Baskerville, Times New Roman",
          fontSize: "16px",
        },
      }}
    />
  );
};

export default SASnackbar;
