import React, { useState, useEffect } from "react";
import { useFetchResults } from "../../shared/useFetchResults";
import { RouteParams } from "../../shared/types/RouteParams";
import { useParams } from "react-router-dom";
import "./Wizard.css";
import { Bars } from "react-loader-spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const WizardStory: React.FC = (): JSX.Element => {
  const [currentChapter, setCurrentChapter] = useState(1);
  const [chapters, setChapters] = useState<string>("");
  const [chapterArray, setChapterArray] = useState<string[]>([]);
  const [copySuccess, setCopySuccess] = useState<string>("");

  useEffect(() => {
    setChapterArray(chapters.split("Chapter "));
  }, [chapters]);

  const { guid } = useParams<RouteParams>();
  useFetchResults({
    actualGuid: guid ?? "",
    setResponseText: setChapters,
    setSnackbarMessage: () => {},
    setSnackbarOpen: () => {},
  });

  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess(`${type} Copied!`);
      setTimeout(() => setCopySuccess(""), 2000); // reset message after 2 seconds
    });
  };

  return (
    <>
      <div className="container">
        {chapterArray ? (
          <div className="inputContainer">
            <label className="label">{`Chapter ${currentChapter} :`}</label>
            <div className="text-display">
              <p>{chapterArray[currentChapter]}</p>
            </div>
            <button style={{background: "transparent", color: "rgb(166, 90, 56)"}} onClick={() => copyToClipboard(chapters, "Story")}>
              <FontAwesomeIcon icon={faCopy} />
            </button>
            {copySuccess && <div>{copySuccess}</div>}
          </div>
        ) : (
          <Bars
            height="80"
            width="80"
            color="#1E1C1B"
            ariaLabel="bars-loading"
          />
        )}
        <button
          className="button"
          disabled={currentChapter === 1}
          onClick={() => setCurrentChapter(currentChapter - 1)}
        >
          Previous
        </button>
        <button
          className="button"
          disabled={currentChapter === chapterArray.length - 1}
          onClick={() => setCurrentChapter(currentChapter + 1)}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default WizardStory;
