import React, { FC } from "react";
import { isLoggedIn, isTokenExpired } from "./shared/misc";
import { Button } from "@material-ui/core";
import theme from "./theme";

type Balance = {
  spend: number;
  max_spend: number;
};

type UserGreetingProps = {
  user: string;
  balance: Balance;
};

const buttonStyle = {
  fontWeight: "bold",
  fontSize: "12px",
  padding: "10px",
  marginLeft: "10px",
  borderRadius: "10px",
  backgroundColor: "transparent",
  color: "#FFF",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
};

const UserGreeting: FC<UserGreetingProps> = ({ user, balance }) => {
  const remainingBalance = balance.max_spend - balance.spend;
  const loggedIn = isLoggedIn();

  const tokenExpired = isTokenExpired();

  console.log("tokenExpired", tokenExpired);
  console.log("loggedIn", loggedIn);
  return !loggedIn || tokenExpired ? (
    <div>
      <Button
        style={buttonStyle}
        onClick={(e) =>
          (window.location.href = `https://login.story-assist.com/login?client_id=3gp2pvnnnkqpm8i0sg02emg84k&response_type=token&scope=email+openid+phone&redirect_uri=${window.location.origin}/signincallback`)
        }
      >
        Sign Up
      </Button>
      <Button
        style={buttonStyle}
        onClick={(e) =>
          (window.location.href =
            `https://login.story-assist.com/login?client_id=3gp2pvnnnkqpm8i0sg02emg84k&response_type=token&scope=email+openid+phone&redirect_uri=${window.location.origin}/signincallback`)
        }
      >
        Login
      </Button>
    </div>
  ) : (
    <div style={{ padding: "10px" }}>
      {/* <span>Hello, {user} </span> */}
      <span>
        {"Remaining Today:"}
        {(remainingBalance && balance.max_spend)  ? (`${remainingBalance} / ${balance.max_spend}`) : ""}
      </span>
    </div>
  );
};

export default UserGreeting;
