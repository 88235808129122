import React from "react";
import { useNavigate } from "react-router-dom";
import { handleAuthentication } from "./shared/misc";
const Callback = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const saveAccessToken = () => {
      handleAuthentication();
      navigate("/");
    };
    saveAccessToken();
  }, [navigate]);

  return null;
};

export default Callback;
