import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import InstructionItem from "./InstructionItem";
import "./InstructionsList.css";

export interface Instruction {
  id: string;
  text: string;
  description: string;
  category: string;
}

interface InstructionsListProps {
  instructions: Instruction[];
  handleDeleteInstruction: (index: number) => void;
  handleDragEnd: (result: any) => void;
  handleAddInstruction: (instruction: Instruction) => void;
  availableInstructions: Instruction[];
  singleInstruction?: boolean;
}

const InstructionsList: React.FC<InstructionsListProps> = ({
  instructions,
  handleDeleteInstruction,
  handleDragEnd,
  handleAddInstruction,
  availableInstructions,
  singleInstruction
}) => {
  const categorizedInstructions = availableInstructions.reduce(
    (acc: { [key: string]: Instruction[] }, instruction: Instruction) => {
      const { category } = instruction;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(instruction);
      return acc;
    },
    {}
  );

  return (
    <div>
      <label>Instructions:</label>
      {!singleInstruction && (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="instructions">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {instructions.map((instruction, index) => (
                <InstructionItem
                  key={`${instruction.id}-${index}`}
                  instruction={instruction}
                  index={index}
                  handleDeleteInstruction={handleDeleteInstruction}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      )}
      <select
        className="dropdown-select"
        defaultValue="default"
        onChange={(e) =>
          handleAddInstruction(
            availableInstructions.find(
              (instruction) => instruction.id === e.target.value
            ) as Instruction
          )
        }
      >
        <option value="default" disabled>
          Add instruction
        </option>
        {Object.entries(categorizedInstructions).map(
          ([category, instructions]) => (
            <optgroup key={category} label={category}>
              {instructions.map((instruction) => (
                <option title={instruction.description} key={instruction.id} value={instruction.id}>
                  {instruction.text}
                </option>
              ))}
            </optgroup>
          )
        )}
      </select>
    </div>
  );
};

export default InstructionsList;
