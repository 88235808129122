import React, { useState } from "react";
import { useFetchResults } from "../../shared/useFetchResults";
import { useParams } from "react-router-dom";
import { RouteParams } from "../../shared/types/RouteParams";
import { BACKEND_URL } from "../../Constants";
import { headers } from "../../shared/misc";
import "./Wizard.css";
import { Bars } from "react-loader-spinner";


const WizardOutline: React.FC = (): JSX.Element => {
  const [currentOutline, setCurrentOutline] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async () => {
    setIsProcessing(true);

    const response = await fetch(`${BACKEND_URL}/process`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        text: currentOutline,
        instructions: ["outline_to_detailed_chapter_outline"],
        model: "gpt-3.5-turbo",
        page: "wizard/chapteroutlines",
      }),
    });

    if (response.ok) {
      const data = await response.json();
      window.location.href = `/wizard/chapteroutlines/${data.guid}`;
    } else {
      alert("An error occurred while processing your request");
    }
    setIsProcessing(false);
  };

  const { guid } = useParams<RouteParams>();
  useFetchResults({
    actualGuid: guid ?? "",
    setResponseText: setCurrentOutline,
    setSnackbarMessage: () => {},
    setSnackbarOpen: () => {},
  });

  return (
    <div className="container">
      {currentOutline ? (
        <div className="inputContainer">
          <label className="label">Edit the story outline:</label>
          <textarea
            value={currentOutline}
            onChange={(e) => setCurrentOutline(e.target.value)}
            className="textarea"
          />
        </div>
      ) : (
        <Bars height="80" width="80" color="#1E1C1B" ariaLabel="bars-loading" />
      )}
      <button className="button" onClick={handleSubmit} disabled={isProcessing}>
        Create Chapter Outlines
      </button>
    </div>
  );
};

export default WizardOutline;
