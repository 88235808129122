import React from "react";
import { TextField } from "@material-ui/core";
import { Bars } from "react-loader-spinner";

interface ResultsBoxProps {
  responseText: string;
  screenWidth: number;
}

const ResultsBox: React.FC<ResultsBoxProps> = ({
  responseText,
  screenWidth,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginLeft: screenWidth >= 768 ? "20px" : "0",
        marginTop: screenWidth < 768 ? "20px" : "0",
        fontFamily: "Roboto Slab, Baskerville, Times New Roman",
      }}
    >
      {responseText ? (
        <TextField
          style={{ marginBottom: "16px", width: "100%" }}
          value={responseText}
          fullWidth
          multiline
          minRows={2}
          maxRows={2}
          variant="outlined"
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Bars height="80" width="80" color="#1E1C1B" ariaLabel="bars-loading" />
        </div>
      )}
    </div>
  );
};

export default ResultsBox;
