export const Commands = [
  {
    id: "expand_story",
    text: "Expand Story",
    category: "Content Editing",
    subcategory: "Story Development",
    description: "Expand the narrative of the story",
  },
  {
    id: "expand_text",
    text: "Expand Text",
    category: "Content Editing",
    subcategory: "Text Development",
    description: "Elaborate and enrich textual content",
  },
  {
    id: "rewrite_adverbs",
    text: "Rewrite Adverbs",
    category: "Grammar Editing",
    subcategory: "Adverb Rewrite",
    description: "Modify the adverb usage in the content",
  },
  {
    id: "rewrite_plot_consistency",
    text: "Rewrite Plot Consistency",
    category: "Content Editing",
    subcategory: "Plot Development",
    description: "Enhance the consistency of the plot",
  },
  {
    id: "rewrite_character_development",
    text: "Rewrite Character Development",
    category: "Content Editing",
    subcategory: "Character Development",
    description: "Improve the development of characters in the story",
  },
  {
    id: "rewrite_dialogue",
    text: "Rewrite Dialogue",
    category: "Content Editing",
    subcategory: "Dialogue Improvement",
    description: "Refine dialogue between characters",
  },
  {
    id: "rewrite_pacing",
    text: "Rewrite Pacing",
    category: "Narrative Editing",
    subcategory: "Pacing Adjustment",
    description: "Adjust the pacing of the story",
  },
  {
    id: "rewrite_scene_structure",
    text: "Rewrite Scene Structure",
    category: "Content Editing",
    subcategory: "Scene Design",
    description: "Improve the structure of scenes",
  },
  {
    id: "rewrite_tone_and_atmosphere",
    text: "Rewrite Tone and Atmosphere",
    category: "Style Editing",
    subcategory: "Tone and Atmosphere Rewrite",
    description: "Adjust the tone and atmosphere of the content",
  },
  {
    id: "rewrite_show_vs_tell",
    text: "Rewrite Show vs Tell",
    category: "Narrative Editing",
    subcategory: "Show vs Tell Balancing",
    description: "Balance 'showing' versus 'telling' in the content",
  },
  {
    id: "rewrite_pov",
    text: "Rewrite Point of View",
    category: "Narrative Editing",
    subcategory: "Point of View Adjustment",
    description: "Alter the point of view in the narrative",
  },
  {
    id: "rewrite_tense",
    text: "Rewrite Tense",
    category: "Grammar Editing",
    subcategory: "Tense Rewrite",
    description: "Correct and improve tense usage",
  },
  {
    id: "rewrite_voice",
    text: "Rewrite Voice",
    category: "Style Editing",
    subcategory: "Voice Rewrite",
    description: "Modify the voice style in the text",
  },
  {
    id: "rewrite_punctuation",
    text: "Rewrite Punctuation",
    category: "Grammar Editing",
    subcategory: "Punctuation Rewrite",
    description: "Correct and enhance punctuation usage",
  },
  {
    id: "rewrite_sentence_structure",
    text: "Rewrite Sentence Structure",
    category: "Grammar Editing",
    subcategory: "Sentence Structure Rewrite",
    description: "Improve the structure of sentences",
  },
 
  {
    id: "rewrite_word_choice",
    text: "Rewrite Word Choice",
    category: "Language Editing",
    subcategory: "Vocabulary Enhancement",
    description: "Refine the choice of words in the text",
  },
  {
    id: "rewrite_passive_voice",
    text: "Rewrite Passive Voice",
    category: "Style Editing",
    subcategory: "Passive Voice Rewrite",
    description: "Transform passive voice into active voice",
  },
  {
    id: "rewrite_redundant_phrases",
    text: "Rewrite Redundant Phrases",
    category: "Language Editing",
    subcategory: "Redundancy Removal",
    description: "Eliminate redundant phrases in the text",
  },
  {
    id: "rewrite_redundant_words",
    text: "Rewrite Redundant Words",
    category: "Language Editing",
    subcategory: "Redundancy Removal",
    description: "Remove unnecessary words in the content",
  },
  {
    id: "rewrite_repeated_words",
    text: "Rewrite Repeated Words",
    category: "Language Editing",
    subcategory: "Repetition Management",
    description: "Alter or remove repeated words in the text",
  },
  {
    id: "rewrite_repeated_phrases",
    text: "Rewrite Repeated Phrases",
    category: "Language Editing",
    subcategory: "Repetition Management",
    description: "Change or remove repeated phrases in the content",
  },
  {
    id: "rewrite_repeated_sentences",
    text: "Rewrite Repeated Sentences",
    category: "Language Editing",
    subcategory: "Repetition Management",
    description: "Revise or eliminate repeated sentences",
  },
  {
    id: "rewrite_repeated_paragraph",
    text: "Rewrite Repeated Paragraphs",
    category: "Language Editing",
    subcategory: "Repetition Management",
    description: "Modify or remove repeated paragraphs",
  },
];
